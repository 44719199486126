<template>
  <div class="about">
    如长时间未反应请刷新页面或返回重新下单
  </div>
</template>
<script>
export default {
  name: 'WechatPay',
  data(){
    return{
    }
  },
  created(){
    /*let date=new Date();//1. js获取当前时间
    let min=date.getMinutes();//2. 获取当前分钟
    date.setMinutes(min+119);//3. 设置当前时间+10分钟：把当前分钟数+10后的值重新设置为date对象的分钟数
    let y = date.getFullYear();
    let m = (date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
    let d = date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate();
    let h = date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours()
    let f = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()
    let s = date.getSeconds() < 10 ? ('0' + date.getseconds()) : date.getSeconds()
    let formatdate = y+'-'+m+'-'+d + " " + h + ":" + f + ":" + s;*/
    this.$notify({ type: 'success', message: "支付订单创建成功,请在5分钟内完成支付",duration:2000});

    this.signPay = this.$route.params.signPay;
      this.axios.post('/app/wx/wechat/'+this.$route.params.signPay, {
      }).then((response) => {
        if(response==undefined || response == "" || response==null){
           alert("response网络加载失败,请返回重新下单支付1!");
            return;
        }
          console.log("返回参数",response);
          console.log(typeof WeixinJSBridge == "undefined");
          if (typeof WeixinJSBridge == "undefined"){
            // alert("网络加载失败,请返回重新下单支付!");
            if( document.addEventListener ){
              document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
            }else if (document.attachEvent){
              document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
            }
            if (typeof WeixinJSBridge == "undefined"){
              alert("网络加载失败,请返回重新下单支付2!");
            }else{
              this.onBridgeReady(JSON.parse(response));
            }
          }else{
            this.onBridgeReady(JSON.parse(response));
          }
      }).catch((error)=> {
          console.log(error);
          this.$router.go(-1);
      });
    // setTimeout(()=>{
    // },100);
  },
  methods:{
    onBridgeReady(obj){
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId":obj.appId,   //公众号名称，由商户传入
            "timeStamp":obj.timeStamp,     //时间戳，自1970年以来的秒数
            "nonceStr":obj.nonceStr, //随机串
            "package":obj.package,
            "signType":obj.signType,       //微信签名方式：
            "paySign":obj.paySign //微信签名
            },
            (res)=>{
              if(res.err_msg == "get_brand_wcpay_request:ok" ) {
                this.$toast.success('支付完成');
              }     // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
              this.$router.go(-1);
            }
      );
    }
  }
}
</script>
<style scoped>
</style>
